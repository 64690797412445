@import "~react-image-gallery/styles/css/image-gallery.css";

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

#basic-nav-dropdown::after {
    content: none;
}

.div-odd {
  display:flex;
  justify-content: center;
  flex-grow: 2;
  overflow:auto;
  background-color: #222;
  min-height: 90vh;
  padding: 20px;
  color: white;
}

.div-pair {
  justify-content: center;
  flex-grow: 1;
  overflow:auto;
  background-color: #fff;
  min-height: 90vh;
  padding: 20px;
  color: black;
  text-align: left;
  padding:10px;
}

.content-pair {
  display:flex;
  justify-content: center;
  color: black;
  align-self: center;
}

.container-section {
  display: grid;
  flex-grow: 1;
  overflow:auto;
  min-height: 90vh;
  text-align: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: rgba(0,0,0);
  flex-direction: column;
}



.container-products {
  min-height: 90vh;
  overflow:auto;
  justify-content: space-around;
  display: inline-block;
}


.column-pair-first {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
  height: 100%
}

.column-pair-second {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-around;
}

.content-odd {
  display:flex;
  justify-content: center;
  color: white;
  align-self: center;
}


.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
